import React, { useState,useEffect,useRef } from 'react';

import CircleMask from './mods/CircleMask';
import Stars from './mods/Stars';
import Badge from './mods/Badge';

import Hero from './sections/Hero';
import Work from './sections/Work';
import Portfolio from './sections/Portfolio';
import Contact from './sections/Contact';

import { LocomotiveScrollProvider } from 'react-locomotive-scroll';

export default function Home(){ 

	const [mobi,setMobi] = useState(false);

	useEffect(() => {
		if(window.innerWidth <= 768){
			setMobi(true)
		}
		const resize = () => {
			if(window.innerWidth <= 768){
				setMobi(true)
			}else {
				setMobi(false)
			}
		}
		window.addEventListener('resize',resize)
		return () => window.removeEventListener("resize", resize);
	},[])

	// viewport
	useEffect(() => {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	},[])

	const containerRef = useRef(null)

	return (
		<LocomotiveScrollProvider
			options={{
				smooth: true,
				// lerp:0.05,
				scrollFromAnywhere:true,
				smartphone: {smooth: false},
				tablet: {smooth: true},
				offset:["-100%",0]
			}}
			watch={[

			]}
			containerRef={containerRef}
			>	
			<div className="container" data-scroll-container ref={containerRef}>
				{!mobi && <CircleMask mobi={mobi}/>}
				{mobi && <Badge/>}
				<Stars/>
				<Hero mobi={mobi}/>
				<Work mobi={mobi}/>
				<Portfolio mobi={mobi}/>
				<Contact mobi={mobi}/>
			</div>
		</LocomotiveScrollProvider>
	)
}
