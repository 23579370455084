import React, { useState, useEffect } from 'react';


export default function Badge(props){

	const [show,setShow] = useState(false);
	
	useEffect(()=>{
		setTimeout(()=>{
			setShow(true)
			setTimeout(()=>{
				setShow(false)
			},7000)
		},1000)
	},[])
	  

	return <div className={`badge ${show ? 'shown':''}`}><p>Work in progress. To view my portfolio, simply tap on the words "Recent Projects" when you scroll further down. For fancy effects, please view on desktop.</p></div>
}