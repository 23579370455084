import React from 'react';

import Letters from '../mods/Letters';
import Paragraph from '../mods/Paragraph';


export default function Hero(props){

	let mobi = props.mobi;

	return (
		<div className="section hero" id="hero" mode={props.crazy ? 'crazy':'normal'} data-scroll-section>
			<div className="text-intro">
				{
					mobi ?
					<Paragraph from="top" target="#hero">
					  	{`Hi there, \nthis is my \ndeveloper \nportfolio.`}
					</Paragraph>
					:
					<Letters crazy={props.crazy ? true:false} from="top" target="#hero">
					  	{`Hi there, \nWelcome to \nmy developer \nportfolio.`}
					</Letters>
				}
			</div>
		</div>
	)
}