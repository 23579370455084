import React from 'react';

import Letters from '../mods/Letters';
import Paragraph from '../mods/Paragraph';

export default function Work(props){

	let mobi = props.mobi;

	return (
		<div className="section work" id="work" mode={props.crazy ? 'crazy':'normal'} data-scroll-section>
			<div className="text-intro">
				{
					mobi ?
					<Paragraph from="top" target="#work">
					  	{`I code in \nReact, php \n& nodejs`}
					</Paragraph>
					:
					<Letters crazy={props.crazy ? true:false} from="top" target="#work">
						{`I build things \nwith ReactJS, \nphp & nodejs`} 
					</Letters>
				}
			</div>
			{
				// props.crazy &&
				// <div className={`video ${props.video ? 'shown':''}`}>
				// 	<video src="https://staging.audimiddleeast.com/assets/video/intro.mp4" type="video/mp4" autoPlay loop muted playsInline preload="false"/>
				// </div>
			}
		</div>
	)
}