import React, { useState, useEffect, useCallback, useRef } from 'react';

import Hero from '../sections/Hero';
import Work from '../sections/Work';
import Portfolio from '../sections/Portfolio';
import Contact from '../sections/Contact';

import { useLocomotiveScroll } from 'react-locomotive-scroll';
import { TweenLite } from "gsap";

const initial = {
  	totalValue: 20
};
const initialLine = {
  	totalValue: 60
};

export default function CircleMask(props){

	let mobi = props.mobi;

	const { scroll } = useLocomotiveScroll();
	
	const [mouse,setMouse] = useState({ x: 0, y: 0 });
	const [trail,setTrail] = useState({ x: 0, y: 0 });
	const [scale,setScale] = useState(20);
	const [hover,setHover] = useState(false);
	const [click,setClick] = useState(false);
	const [video,setVideo] = useState(false);
	const [fixed,setFixed] = useState(false);

	let max = window.innerWidth >= window.innerHeight ? window.innerWidth:window.innerHeight,
		min = window.innerWidth <= window.innerHeight ? window.innerWidth:window.innerHeight;

	const [viewport,setViewport] = useState({min:min,max:max});
	
	const [animateTo, setAnimateTo] = useState(20);
	const [animateLineTo, setAnimateLine] = useState(60);

	const trailRef = useRef();
	const prevtrailTimeRef = useRef();


	const tracer = time => {
		if (prevtrailTimeRef.current !== undefined) {
	      	const deltaTime = time - prevtrailTimeRef.current;
	      	let { x, y } = trail;
				x += (mouse.x - x) / 15;
		  		y += (mouse.y - y) / 15;
	      	setTrail((prevTrail) => {
	      		return {x:x,y:y}
	      	})
	    }
	    prevtrailTimeRef.current = time;
	    trailRef.current = requestAnimationFrame(tracer);
	}

	// mouse

	const mouseMove = useCallback(({target:target,clientX: x, clientY: y}) => {
		setMouse({ x, y });
		// hover
		if(target.classList.contains('hero-letter')){
			setHover(true);
			setVideo(false);
			setClick(false);
			if(target.closest('.work')){
				// setVideo(true);
				// animateNumber(viewport.min);
				animateNumber(300);
			}else if(target.closest('.portfolio')){
				setClick(true);
				animateNumber(200);
			}else if(target.closest('.contact')){
				setClick(true);
				animateNumber(300);
			}else {
				setVideo(false);
				animateNumber(300);
			}
		}else {
			setClick(false);
			setVideo(false);
			setHover(false);
			animateNumber(20);
		}
	}, []);

	useEffect(() => {
		if(!fixed){
			window.addEventListener("mousemove", mouseMove);
		}else {
			window.removeEventListener("mousemove", mouseMove);
		}
	}, [mouse,fixed]);

	useEffect(() => {
		trailRef.current = requestAnimationFrame(tracer);
		return () => cancelAnimationFrame(trailRef.current);
	}, [trail]);

	// scroll

	useEffect(() => {
		const onScroll = e => {
			setVideo(false);
			animateNumber(20)
    	};
    	if(!fixed){
			window.addEventListener("mousewheel", onScroll);
		}else {
			window.removeEventListener("mousewheel", onScroll);
		}
		return () => window.removeEventListener("mousewheel", onScroll);
	}, [fixed]);

	// resize

	useEffect(() => {
		const onResize = e => {
			let max = window.innerWidth >= window.innerHeight ? window.innerWidth:window.innerHeight,
				min = window.innerWidth <= window.innerHeight ? window.innerWidth:window.innerHeight;
			setViewport({min:min,max:max});
    	};
		window.addEventListener("resize", onResize);
		return () => window.removeEventListener("resize", onResize);
	},[])


	// fn

	const animateNumber = (number) => {
		TweenLite.to(initial, 0.5, {
	      	totalValue: number,
	      	roundProps: "totalValue",
	      	onUpdate: () => {
	        	setAnimateTo(initial.totalValue);
	      	}
	    });
	}

	const animateLine = (number) => {
		TweenLite.to(initialLine, 0.5, {
	      	totalValue: number,
	      	roundProps: "totalValue",
	      	onUpdate: () => {
	        	setAnimateLine(initialLine.totalValue);
	      	}
	    });
	}

	// open showcase

	const expandWork = () => {
		setFixed(true);
		if(scroll){
			scroll.stop()
		}
		animateNumber(viewport.max);
		animateLine(300);
		setTimeout(()=>{
			animateLine(60);
		},2000)  
	}

	// close showcase

	const closeWork = () => {
		// animate mouse pos
		let close = document.querySelector('.showcase .close span').getBoundingClientRect();
		setMouse({x:close.right - close.width/2,y:close.top + close.height/2})
		setTimeout(()=>{
			animateNumber(20);
			setTimeout(()=>{
				setFixed(false);
				setTimeout(()=>{
					if(scroll){
						scroll.start()
					}
				},500)  
			},1000) 
		},300)
	}

	// open CV
	const openCV = () => {
		window.location.href = 'https://kieranharris.dev/cv.pdf';
	}

	// render

	const style = { '--position':`${trail.x}px ${trail.y}px`,'--size':`${animateTo}px`,'--sizeBlue':`${animateLineTo}px`  }
	return (
		<div className={`circle-mask ${hover ? 'hover':''} ${click ? 'click':''} ${fixed ? 'fixed':''}`} style={style}>
			<Hero crazy={true} mobi={mobi}/>
			<Work crazy={true} video={video} mobi={mobi}/>
			<Portfolio crazy={true} triggerOpen={expandWork} triggerClose={closeWork} mobi={mobi}/>
			<Contact crazy={true} openCV={openCV} mobi={mobi}/>
		</div>
	)
}
