import React, {useState} from 'react';

import Letters from '../mods/Letters';
import Paragraph from '../mods/Paragraph';

import projects from '../json/projects.json';

export default function Portfolio(props){

	let mobi = props.mobi;

	const [showcase,setShowCase] = useState(false);
	const [active,setActive] = useState(1);

	const handleOpen = () => {
		if(mobi || props.crazy){
			if(!mobi){
				props.triggerOpen();
			}
			if(mobi){
				document.querySelector('html').classList.add('has-scroll-smooth')
			}
			setShowCase(true)
		}
	}

	const handleClose = () => {
		if(mobi || props.crazy){
			if(!mobi){
				props.triggerClose();
			}
			if(mobi){
				document.querySelector('html').classList.remove('has-scroll-smooth')
			}
			setShowCase(false)
		}
	}

	const next = (index) => {
		if(active === projects.length){
			setActive(1)
			return;
		}
		setActive(active+1)
	}

	return (
		<>
		<div className="section portfolio" id="portfolio" mode={props.crazy ? 'crazy':'normal'} data-scroll-section onClick={handleOpen}>
			<div className="text-intro">
				{
					mobi ?
					<Paragraph from="top" target="#portfolio">
					  	{`Check out \nsome of \nmy recent \nprojects`}
					</Paragraph>
					:
					<Letters crazy={props.crazy ? true:false} from="top" target="#portfolio">
						{`Check out \nsome of my \nprojects`} 
					</Letters>
				}
			</div>
		</div>
		{
			(props.crazy || mobi) &&
			<div className={`showcase-wrapper ${showcase ? 'active':''}`}>
				<div className="showcase">
					<div className="close" onClick={handleClose}><span>&times;</span></div>
					<div className="showcase-items">
						<div className="showcase-item">
							<div className={`showcase-video ${projects[active-1].align}`}>
								<video width="100%" height="50%" autoPlay loop playsInline muted src={`${process.env.PUBLIC_URL}/assets/video/${projects[active-1].video}.mp4`}></video>
							</div>
							<div className="showcase-inner">
								<div className="header">
									<div className="title">
										<h1>{projects[active-1].title}</h1>
										<h3>{projects[active-1].subtitle} | {projects[active-1].date}</h3>
									</div>
									<div className="actions">
										<a href={projects[active-1].link} className="purple" target="_blank">View website</a>
										<button onClick={next}>Next project</button>
									</div>
								</div>
								<div className="table">
									<div className="row">
									{
										projects[active-1].info.map((el,k)=>{
											return (
												<div className="item" key={k}>
													<h2>{el.title}</h2>
													<h4>{el.body}</h4>
												</div>
											)
										})
									}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>	
			</div>
		}
		</>
	)
}