import React from 'react';
import ReactDOM from 'react-dom';

// styles
import './theme/styles.scss';
// pages
import Home from './Home';
ReactDOM.render(
	<Home />,
  	document.getElementById('root')
);

