import React from 'react';

import Letters from '../mods/Letters';
import Paragraph from '../mods/Paragraph';

export default function Contact(props){

	let mobi = props.mobi;

	const handleClick = () => {
		if(props.crazy & !mobi){
			props.openCV();
		}
		if(mobi){
			window.location.href = 'https://kieranharris.dev/cv.pdf';
		}
	}

	return (
		<div className="section contact" id="contact" mode={props.crazy ? 'crazy':'normal'} data-scroll-section onClick={handleClick}>
			<div className="text-intro">
				{
					mobi ?
					<Paragraph from="top" target="#portfolio">
					  	{`Tap here. \nFor my CV.`}
					</Paragraph>
					:
					<Letters crazy={props.crazy ? true:false} from="top" target="#portfolio">
						{`Get in touch.\nview my CV.`} 
					</Letters>
				}
			</div>
		</div>
	)
}