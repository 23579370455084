import React, { useState, useEffect, useCallback, useRef, createRef } from 'react';


export default function Paragraph(props){

	const [show,setShow] = useState(false);
	
	useEffect(() => {
		setTimeout(()=>{
			setShow(true);
		},250)
	});

	// span creation
	function getSpanElement(elem, className, index, globindex, spacer, count){
		let color = 'white';
		if(!spacer){
			if(elem === ',' || elem === '.' || elem === "'" || elem === '-'){
				color = 'lime';
			}else {
				if(globindex === 1 || globindex % 4 === 0){
					color = 'blue';
				}else if(globindex === 2 || globindex % 4 === 1){
					color = 'pink';
				}else if(globindex === 3 || globindex % 4 === 2){
					color = 'orange';
				}else if(globindex === 4 || globindex % 4 === 3){
					color = 'yellow';
				}
			}
		}
		const key = `${className}${index} ${color}`;
		let skew = index % 2 === 0 ? -2:-2; 

		let factor = 0,j = index;
		if(className === 'char'){
			factor = j < Math.ceil(count/2) ? j : Math.ceil(count/2) - Math.abs(Math.floor(count/2) - j+2) - 1;
		}

		return <span key={key} className={`${className} ${key} hero-letter`}>{elem}{spacer}</span>
	}
	function getWrappedChars(){
		let lineIndex = 0, wordIndex = 0, globIndex = 0, wordTotal = 0;
		const { children } = props;
		const childrenArr = children.split('\n');
	    const lines = childrenArr.map(line => {
	    	let charIndex = 0;
	      	lineIndex += 1
	      	let count = line.replace(/ /g, '').length;
			const wordsArray = line.split(' ');
			const words = wordsArray.map(word => {
				wordIndex += 1
				const charsArray = word.split('')
				const chars = charsArray.map((char,i) => {
					if(char !== ',' && char !== '.' && char !== "'" && char !== '-'){
						charIndex += 1;
						globIndex += 1
					}
					return getSpanElement(char, 'char', charIndex, globIndex, false, count)
				})
				return getSpanElement(chars, 'word', wordIndex, wordIndex, ' ')
			})
			return getSpanElement(words, 'line', lineIndex, lineIndex, ' ')
	    })
		return lines
  	}
	const wrappedChars = getWrappedChars();

	return <h1 className={`letters ${show ? 'show':''}`}>{wrappedChars}</h1>
}